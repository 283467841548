import React from 'react';

import { Grid } from '@mui/material';
import { TkTaskWrapperItem } from './styles';
import { TkTypography } from '@components/index';
import { useTranslation } from 'react-i18next';

const TkTimeReportLabel = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TkTaskWrapperItem padding="1rem">
      <Grid container justifyContent="start" alignItems="center">
        <TkTypography
          fontWeight="bold"
          fontSize={'19px'}
          fontFamily="Muli"
          textAlign="left"
          color="#011F41"
        >
          {t('timeReport', { ns: 'events' })}
        </TkTypography>
      </Grid>
    </TkTaskWrapperItem>
  );
};

export default TkTimeReportLabel;
