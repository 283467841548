import {
  ApiResultsKind,
  TaskFrequencyEnum,
  TaskFrequencyIntervalEnum,
} from '@consts/index';
import { getDateToEpoch, userTimezone } from '@helpers/index';
import api from '@services/api';

const markAsComplete = (
  id: number,
  completeForEveryone = false
): Promise<BaseResult & { projectId: number }> => {
  return api
    .post(`project/${id}/finish`, {
      complete_for_everyone: completeForEveryone,
    })
    .then((response) => {
      return { kind: ApiResultsKind.OK, projectId: response.data.projectId };
    })
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR, projectId: 0 }));
};

const deleteTask = (id: number): Promise<BaseResult> => {
  return api
    .delete(`project/${id}`)
    .then(() => {
      return {
        kind: ApiResultsKind.OK,
      };
    })
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));
};

const deleteRepeatingTask = (
  id: number,
  projectId: number
): Promise<BaseResult> =>
  api
    .post(`project/delete-recurring`, {
      recurring_project_rule_id: id,
      project_id: projectId,
    })
    .then(() => {
      return { kind: ApiResultsKind.OK };
    })
    .catch(() => {
      return Promise.reject({ kind: ApiResultsKind.ERROR });
    });

const updateEventApi = (
  id: number,
  updatedEvent: IEventUpdateApi
): Promise<BaseResult & { message?: string }> =>
  api
    .put(`project/${id}`, updatedEvent)
    .then(() => {
      return { kind: ApiResultsKind.OK };
    })
    .catch((res) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        message: res.response.data,
      })
    );

const updateTimeReport = (
  id: number,
  updatedEvent: any
): Promise<BaseResult & { message?: string }> =>
  api
    .post(`project/${id}/projectTimeReport`, updatedEvent)
    .then(() => {
      return { kind: ApiResultsKind.OK };
    })
    .catch((res) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        message: res.response.data,
      })
    );

const removeImageFromTask = (id: number): Promise<BaseResult> => {
  return api
    .delete(`image/${id}`)
    .then(() => {
      return { kind: ApiResultsKind.OK };
    })
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));
};

const uploadEventImages = (images: File[]) => {
  const formData = new FormData();
  images.forEach((image) => {
    formData.append('images[]', image);
  });

  return api
    .post(`image`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      return {
        kind: ApiResultsKind.OK,
        images: res.data,
      };
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject({ kind: ApiResultsKind.ERROR, images: null });
    });
};

const createNewEvent = (
  newEvent: INewEventApi
): Promise<CreateNewEventResults> => {
  return api
    .post(`project`, newEvent)
    .then((res) => {
      return {
        kind: ApiResultsKind.OK,
        id: res.data.id,
        event: res.data,
      };
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        id: 0,
        event: null,
      });
    });
};

const createNewRepeatEvent = (
  newEvent: INewRepeatEventApi
): Promise<CreateNewRepeatEventResults> => {
  return api
    .post(`project/create-recurring`, newEvent)
    .then((res) => {
      return {
        kind: ApiResultsKind.OK,
        firstId: res.data.projects[0].project.id,
        events: res.data.projects,
      };
    })
    .catch((e) => {
      console.error(e);
      return {
        kind: ApiResultsKind.ERROR,
        firstId: 0,
        events: null,
      };
    });
};

const generateApiEvent = async (
  event: IEvent,
  accountId: number,
  flagNotify: boolean,
  checklists: IChecklistTask[],
  assignees: IAssignee[]
): Promise<INewEventApi> => {
  const { end, title, notes, start, location, customer, imagesFile } = event;

  const newEvent: INewEventApi = {
    notify_assignees: flagNotify,
    name: title,
    account_id: accountId,
    start_time_epoch: getDateToEpoch(start).toString(),
    end_time_epoch: getDateToEpoch(end).toString(),
    timezone: userTimezone(),
  };

  if (location !== undefined) {
    newEvent.location_id = location.id;
  }

  if (customer !== undefined) {
    newEvent.customer_id = customer.id;
  }

  if (notes.length !== 0) {
    newEvent.notes = notes;
  }

  if (checklists.length > 0) {
    newEvent.checklists = checklists.map((c) => {
      return {
        id: c.id,
        is_mandatory: c.isMandatory ? 1 : 0,
      };
    });
  }

  if (assignees.length > 0) {
    newEvent.user_id = assignees.map((a) => a.id);
  }

  if (imagesFile.length === 0) return newEvent;

  newEvent.pictures_ids = [];

  const { images } = await uploadEventImages(imagesFile);

  images.forEach((image) => {
    if (image) newEvent.pictures_ids?.push(image.id);
  });
  return newEvent;
};

const generateRepeatApiEvent = async (
  event: IEvent,
  accountId: number,
  flagNotify: boolean,
  checklists: IChecklistTask[],
  assignees: IAssignee[]
): Promise<INewRepeatEventApi> => {
  const { end, start, taskRepeat } = event;
  const newEvent = await generateApiEvent(
    event,
    accountId,
    flagNotify,
    checklists,
    assignees
  );

  const newRepeatEvent: INewRepeatEventApi = {
    ...newEvent,
    frequency: taskRepeat.frequency,
    interval: taskRepeat.interval,
    start: getDateToEpoch(start).toString(),
    until: getDateToEpoch(end).toString(),
  };

  switch (taskRepeat.frequency) {
    case TaskFrequencyIntervalEnum.WEEKLY:
      newRepeatEvent.days_of_the_week = taskRepeat.weeklyDaysRepetition;
      break;

    case TaskFrequencyIntervalEnum.MONTHLY:
      newRepeatEvent.recurrence_type = taskRepeat.monthlyType;
      if (taskRepeat.monthlyType === TaskFrequencyEnum.BY_MONTH_DAY) {
        newRepeatEvent.days_of_the_month = taskRepeat.monthlyEachRepetition;
      } else if (taskRepeat.monthlyType === TaskFrequencyEnum.BY_DAY_TYPE) {
        newRepeatEvent.day_name = taskRepeat.monthlyOnTheRepetition[1];
        newRepeatEvent.day_number = taskRepeat.monthlyOnTheRepetition[0];
      }
      break;

    case TaskFrequencyIntervalEnum.YEARLY:
      newRepeatEvent.months = taskRepeat.yearlyMonthRepetition;
      newRepeatEvent.day_name = taskRepeat.yearlyOnTheRepetition[1];
      newRepeatEvent.day_number = taskRepeat.yearlyOnTheRepetition[0];
      break;

    default:
      break;
  }

  return newRepeatEvent;
};

export {
  deleteTask,
  updateEventApi,
  updateTimeReport,
  markAsComplete,
  createNewEvent,
  generateApiEvent,
  uploadEventImages,
  removeImageFromTask,
  deleteRepeatingTask,
  createNewRepeatEvent,
  generateRepeatApiEvent,
};
