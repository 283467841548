export default {
  taskName: 'Task Name',
  saveChanges: 'Save Changes',
  requestedStart: 'Requested Start',
  requestedFinish: 'Requested Finish',
  timeNotSet: 'Time not set',
  notSet: 'Not set',
  assignedTo: 'Assigned to',
  assignTask: 'Assign Task',
  teammateImages: 'Teammate Images',
  checklists: 'Checklists',
  teammatesNotes: 'Teammates Notes',
  inventory: 'Inventory',
  startTimeBeforeEndTimeWarning:
    'The starting time of your task must be before its ending time',
  customerAndLocation: 'Customer & Location',
  dateMustBeSet: 'Please add a {{period}} time to save the changes',
  pleaseAddAName: 'Please add a name',
  singleTask: 'Single Task',
  repeat: 'Repeat',
  startDate: 'Start Date',
  startTime: 'Start Time',
  endRepeat: 'End Repeat',
  endDate: 'End Date',
  endTime: 'End Time',
  timeReport: 'Time Report',
  totalTimeSpent: 'Total Time Spent',
  totalEarned: 'Total Earned',
  totalHours: 'hrs',
  errorHours: 'Time in future',
  totalMin: 'min',
  rate: 'Rate',
  timeReportWarning:
    'You may adjust the info as needed but keep in mind the customer will be notified',
  timeReportParagraphTitle: 'How long did this task take your team?',
  timeReportParagraphInfo:
    'When working on a team, start and finish times should cover your team’s complete working time.',
  timeReportTextAreaInfo:
    'It seems you needed to adjust the task’s times. Please state why',
  timeReportTextAreaPlaceholder:
  'Leave the customer an explanation',
  timeReportTextAreaLabel:
  'per hour',
  mandatoryCompletion: 'Mandatory Completion',
  mandatoryCompletionTooltip:
    'Require your teammates to check each item off your checklist before completing the task.',
  mandatory: 'mandatory',
  leaveANoteToYourTeammates: 'Leave a note to your teammates',
  nameYourTask: 'Name your Task',
  errorCreatingTask:
    'An error ocurred while creating your task. Please try again',
  pleaseSelectADate: 'Please select a date',
  pleaseSelectATime: 'Please select a time',
  editTaskDetails: 'Edit Task Details',
  endDateOutOfInterval: 'End date should be in interval',
  monthUnselectedError: 'Please select at least one month',
  startDateCantBeTheSameDay: 'Start date can’t be the same day',
  startTimeCantBeEqualEnd: 'Start time can’t be equal end',
  endDateCantBeTheSameDay: 'End date can’t be the same day',
  endTimeCantBeBeforeStart: 'End time can’t be before start',
  notStarted: 'Not Started',
  editRepeatDrawer: {
    editingRepeatingTaskWarning:
      'You are editing a repeating tasks series, all changes made will affect this task and future tasks.',
    datesAndRepetitionCantBeEdited:
      'Dates and repetition settings can’t be edited',
  },
  taskImages: 'Task Images',
  taskNotes: 'Task Notes',
  selectALocation: 'Select a Location',
  selectACustomer: 'Select a Customer',
  thisTaskIsPartRepeatingSeries: 'This task is part of a repeating series',
  editSeries: 'Edit series',
  deletedTask: {
    warning: 'This task has been deleted',
  },
  deleteModalTask: {
    titleRecurring: 'You are deleting a repeating task',
    middleTextRecurring:
      'Would you like to delete this task or this and future tasks?',
    middleTextSingleTask: 'Are you sure you want to delete this task?',
    bottomTextWarning: 'This action can not be undone',
    singleTextDelete: 'Only this task',
    futureTasksText: 'This and future tasks',
  },
  error: {
    taskNotFound: 'This task was not found',
    offerNotFound: 'This offer was not found',
  },
  checklistsExtendedDrawer: {
    emptyTittle: 'You don’t have any checklists',
    emptySubTittle: 'Use Taskbird’s mobile app to create one',
    selectChecklists: 'Select Checklists',
  },
  customersExtendedDrawer: {
    emptyTittle: 'You don’t have any Customers',
  },
  modalComplete: {
    markAsComplete: 'Complete for everyone',
    title: 'Complete for Everyone',
    subText:
      'Are you sure you want to mark this task as completed for everyone? This action can’t be undone',
  },
  modalMandatory: {
    title: 'Mandatory Completion',
    subText:
      'All items on the checklist must be checked off before completing the task.',
    goToChecklist: 'Go to Checklist',
  },
  modalTeammate: {
    title: 'Remove Teammate',
    removeTeammateText:
      'Are you sure you want to remove {{name}}? This task will be marked as completed since everyone else has finished',
    removeTeammateText_plural:
      'Are you sure you want to remove {{name}}? This task will be marked as completed',
    removeTeammate: 'Remove Teammate',
    removeTeammate_plural: 'Remove Teammates',
  },
  taskMarkedAsComplete: 'Task marked as complete successfully',
  markCompleteForEveryone: 'Mark as complete for everyone',
  deleteTask: 'Delete task',
  contactCustomerSupport: 'Contact Customer Support',
};
