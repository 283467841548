import styled from 'styled-components';

import { Container } from '@mui/material';

export const TkTaskWrapper = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    flex-direction: column;
    padding-bottom: 1rem;
    padding: 0;
    position: relative;
  }
`;

export const TkTaskActions = styled(Container)`
  &.MuiContainer-root {
    border-top: 1px solid #999999;
    display: flex;
    min-height: 65px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }
`;
