import { MainDrawersTypes, ExtendedDrawersTypes } from './drawer.types';
import {
  TkNewTaskDrawer,
  TkEditTaskDrawer,
  TkAvailabilityDrawer,
  TkTimeReportDrawer,
} from '@components/Drawers';
import {
  TkProjectAdditionalInfoBooking,
  TkProjectServicesDetails,
  TkTaskChecklistList,
  TkTaskImages,
  TkTaskNotes,
  TkTeammatesImages,
  TkTeammatesNotes,
  TkInventoryList,
  TkCustomerList as TkEditCustomerList,
  TkLocationList as TkEditLocationList,
} from '@components/Drawers/TkEditTaskDrawer/ExtendedDrawers';
import { TkDrawerTurnoProjectAdditionalInfo } from '@components/Drawers/TkEditTaskDrawer/ExtendedDrawers/TkDrawerTurnoAdditionalInfo';
import TkCustomerList from '@components/Drawers/TkNewTaskDrawer/ExtendedDrawers/TkCustomerList';
import TkLocationList from '@components/Drawers/TkNewTaskDrawer/ExtendedDrawers/TkLocationList';
import { TkAssigneeList, TkChecklistsList } from '@components/ExtendedDrawers';

export const MainDrawers: Record<string, (props) => JSX.Element> = {
  [MainDrawersTypes.NEW_TASK]: TkNewTaskDrawer,
  [MainDrawersTypes.EDIT_TASK]: TkEditTaskDrawer,
  [MainDrawersTypes.TIME_REPORT]: TkTimeReportDrawer,
  [MainDrawersTypes.AVAILABILITY]: TkAvailabilityDrawer,
};

export const ExtendedDrawers: Record<string, (props) => JSX.Element> = {
  // NEW TASK DRAWER EXTENDED DRAWERS
  [ExtendedDrawersTypes.NEW_TASK_CUSTOMER]: TkCustomerList,
  [ExtendedDrawersTypes.NEW_TASK_LOCATION]: TkLocationList,

  // SHARED DRAWERS
  [ExtendedDrawersTypes.TASK_ASSIGNEE_LIST]: TkAssigneeList,
  [ExtendedDrawersTypes.TASK_CHECKLIST]: TkChecklistsList,

  // EDIT TASK DRAWER EXTENDED DRAWERS
  [ExtendedDrawersTypes.EDIT_TASK_CUSTOMER]: TkEditCustomerList,
  [ExtendedDrawersTypes.EDIT_TASK_LOCATION]: TkEditLocationList,
  [ExtendedDrawersTypes.EDIT_TASK_NOTES]: TkTaskNotes,
  [ExtendedDrawersTypes.EDIT_TASK_IMAGES]: TkTaskImages,
  [ExtendedDrawersTypes.EDIT_TASK_ADDITIONAL_INFO_BOOKING]:
    TkProjectAdditionalInfoBooking,
  [ExtendedDrawersTypes.EDIT_TASK_ADDITIONAL_INFO_TURNO]:
    TkDrawerTurnoProjectAdditionalInfo,
  [ExtendedDrawersTypes.EDIT_TASK_SERVICE_DETAILS]: TkProjectServicesDetails,
  [ExtendedDrawersTypes.EDIT_TASK_TEAMMATES_NOTES]: TkTeammatesNotes,
  [ExtendedDrawersTypes.EDIT_TASK_TEAMMATES_IMAGES]: TkTeammatesImages,
  [ExtendedDrawersTypes.EDIT_TASK_CHECKLIST_LIST]: TkTaskChecklistList,
  [ExtendedDrawersTypes.EDIT_TASK_INVENTORY_LIST]: TkInventoryList,
};
