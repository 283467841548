import React from 'react';
import { useTranslation } from 'react-i18next';

import { TkTimeReportTotalTimeSpentLine } from '@components/Drawers/TkTimeReportDrawer/components';
import { HorizontalDivider, TkTaskWrapperItem } from './styles';
import { TkTimeReportTotalTimeProps } from '@components/Drawers/TkTimeReportDrawer/interfaces/timeReport';

const TkTimeReportTotalTimeSpent = ({
  totalTimeSpent,
  totalEarned,
  rate,
}: TkTimeReportTotalTimeProps): JSX.Element => {

  const { t } = useTranslation();
  
  return (
    <TkTaskWrapperItem padding="1rem">
      <TkTimeReportTotalTimeSpentLine
        rate={totalTimeSpent}
        label={t('totalTimeSpent', { ns: 'events' })}
      />
      <HorizontalDivider />
      <TkTimeReportTotalTimeSpentLine
         rate={rate}
         label={t('rate', { ns: 'events' })} />
      <HorizontalDivider />
      <TkTimeReportTotalTimeSpentLine
        rate={totalEarned}
        label={t('totalEarned', { ns: 'events' })}
      />
      <HorizontalDivider />
    </TkTaskWrapperItem>
  );
};

export default TkTimeReportTotalTimeSpent;
