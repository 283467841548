import React, { useState, useEffect } from 'react';

import { useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SyncModal } from '@components/index';
import { ApiResultsKind, LocalStorage } from '@consts/index';
import { useModal } from '@contexts/index';
import { getUserAccountId, removedIntegrationsData } from '@recoilData/index';
import { integrationsMethods } from '@services/index';
import {
  disableRemovedIntegrationNotification,
  fetchUserInfo,
} from '@services/userMethods';

interface IIntegration {
  GoogleCalendar;
  TurnoverBnB;
}

interface IRemovedIntegration {
  [key: string]: string;
}

interface IUseIntegrations {
  integrations: IIntegration | undefined;
  setIntegrations: React.Dispatch<
    React.SetStateAction<IIntegration | undefined>
  >;
  refetchIntegrations: () => void;
  isFetchingIntegrations: boolean;
  removedIntegrations: IRemovedIntegration;
  handleDisableRemovedIntegrationNotification: () => Promise<void>;
  requestGoogleCalendarAccess: () => void;
}

const useIntegrations = (refetch = null): IUseIntegrations => {
  const [integrations, setIntegrations] = useState();
  const [removedIntegrations, setRemovedIntegrations] = useRecoilState(
    removedIntegrationsData
  );
  const [enabled, setEnabled] = useState(false);
  const accountId = useRecoilValue(getUserAccountId);
  const { setupModal, handleCloseModal } = useModal();
  const { loadIntegrationsApiData } = integrationsMethods;

  useEffect(() => {
    if (accountId !== 0) {
      setEnabled(true);
      fetchUserInfo().then(({ kind: kindInfo, user }) => {
        if (kindInfo === ApiResultsKind.OK) {
          setRemovedIntegrations(user.notify_removed_integration);
        }
      });
    }
  }, [accountId]);

  const { refetch: refetchIntegrations, isFetching: isFetchingIntegrations } =
    useQuery(
      ['integrationsDataQuery'],
      () => {
        return loadIntegrationsApiData(accountId)
          .then(({ kind, integrations }) => {
            if (kind === ApiResultsKind.OK) {
              setIntegrations(integrations);
            }
          })
          .catch((e) => console.error(e));
      },
      {
        enabled,
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  const handleDisableRemovedIntegrationNotification = async () => {
    const removedIntegrationId = Object.keys(removedIntegrations)[0];

    await disableRemovedIntegrationNotification(
      String(accountId),
      removedIntegrationId
    );
    setRemovedIntegrations(null);
  };

  const addStorageEvent = (event) => {
    if (event.storageArea !== localStorage) return;
    if (
      event.key === LocalStorage.GOOGLE_CALENDAR_SELECTION &&
      event.newValue
    ) {
      localStorage.removeItem(LocalStorage.GOOGLE_CALENDAR_SELECTION);
      handleAddCalendar();
    }
  };
  const handleAddCalendar = async () => {
    setupModal({
      content: (
        <SyncModal
          accountId={accountId}
          handleSuccess={() => {
            refetchIntegrations();
            refetch && refetch();
          }}
          handleClose={handleCloseModal}
        />
      ),
    });
    window.removeEventListener('storage', addStorageEvent);
  };

  const requestGoogleCalendarAccess = () => {
    const scopes = encodeURIComponent(
      'https://www.googleapis.com/auth/calendar.readonly email'
    );

    window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?` +
        `&redirect_uri=${`${process.env.REACT_APP_API_URL}account/integrations/new-google-integration`}` +
        `&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&state=${accountId}&scope=${scopes}` +
        `&response_type=code&access_type=offline&prompt=consent`,
      'googleCalendarPopup',
      'innerWidth=600,innerHeight=500,left=400,top=200'
    );

    window.addEventListener('storage', addStorageEvent);
  };

  return {
    integrations,
    isFetchingIntegrations,
    removedIntegrations,
    setIntegrations,
    refetchIntegrations,
    handleDisableRemovedIntegrationNotification,
    requestGoogleCalendarAccess,
  };
};

export { useIntegrations };
