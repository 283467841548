import React, { useState, forwardRef, useMemo, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Divider,
  TimeItem,
  TimeSelector,
  TimeWrapper,
  TimeOnlyHeader,
  TimeSelectorItem,
  HorizontalSlider,
  TkHourPickerWrapper,
  TkDatePickerWrapper,
  TimeSelectorItemSelected,
} from './styles';
import TkIcon from '@components/TkIcon';

import { DateTime } from 'luxon';

import TkTooltip from '@components/TkTooltip';
import TkTypography from '@components/TkTypography';

import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';

const ActivatorSlot = styled.div`
  width: 100%;
`;
const ComponentInput = forwardRef((props: any, ref) => {
  const activatorClone = React.cloneElement(props.activator, {
    value: props.value,
  });
  return (
    <ActivatorSlot
      onClick={() => {
        props.setCurrentView('false');
        props.onClick();
      }}
    >
      {activatorClone}
    </ActivatorSlot>
  );
});

const TkFullDatePicker = (props: any) => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState('false');
  const {
    startDate,
    handleDateChange,
    activator,
    hourOnly,
    format24h,
    isRepeat = false,
    timezone,
  } = props;

  const [option, setOption] = useState<string>(() => {
    const date = startDate ?? new Date();

    return DateTime.fromJSDate(date)
      .setZone(timezone)
      .toFormat('a')
      .toUpperCase();
  });
  const [lHours, setLHours] = useState<string>(() => {
    const date = DateTime.fromJSDate(startDate ?? new Date());

    return format24h === '24' ? date.toFormat('HH') : date.toFormat('hh');
  });
  const [lMinutes, setLMinutes] = useState<string>(() => {
    return startDate ? String(new Date(startDate).getMinutes()) : '0';
  });

  useEffect(() => {
    const date = DateTime.fromJSDate(startDate ?? new Date()).setZone(timezone);
    const newHour = getDateAmPm();

    if (newHour.getTime() !== date.toJSDate().getTime()) {
      setLHours(format24h === '24' ? date.toFormat('HH') : date.toFormat('hh'));
      setLMinutes(date.toFormat('mm'));
      setOption(date.toFormat('a'));
    }
  }, [startDate]);

  const memoizedHighlightDates = useMemo(() => {
    if (props.weekly) {
      const dates: Array<Date> = [];

      let startRangeDate = DateTime.fromJSDate(startDate)
        .setZone(timezone)
        .set({
          weekday: 0,
        });
      const endRangeDates = DateTime.fromJSDate(startDate)
        .setZone(timezone)
        .set({ weekday: 7 });

      while (startRangeDate.diff(endRangeDates, 'days').days !== 0) {
        dates.push(startRangeDate.toJSDate());
        startRangeDate = startRangeDate.plus({ days: 1 });
      }

      return dates;
    }
    return false;
  }, [startDate]);

  const CustomHeader = (props: any) => {
    const [month, year] = props.title.split(' ');
    return (
      <Grid
        container
        justify="space-between"
        className="react-datepicker__custom-header"
      >
        <Grid item xs={2}>
          <span onClick={props.decreaseMonth}>
            <TkIcon className="fas fa-chevron-left" color="primary" />
          </span>
        </Grid>
        <Grid item xs={8}>
          {currentView === 'false' ? (
            <>
              <span onClick={() => setCurrentView('month')}>{month}</span>&nbsp;
            </>
          ) : (
            ''
          )}
          <span onClick={() => setCurrentView('year')}>{year}</span>
        </Grid>
        <Grid item xs={2}>
          <span onClick={props.increaseMonth}>
            <TkIcon className="fas fa-chevron-right" color="primary" />
          </span>
        </Grid>
      </Grid>
    );
  };

  const viewChangeMapping: any = {
    year: 'month',
    month: 'false',
  };

  const handleViewChangeBasedOnCurrentView = (view: string): string => {
    return viewChangeMapping[view] ?? undefined;
  };

  const handleOption = (op: string) => {
    const newDate = getDateAmPm(op);
    handleDateChange(newDate);
    setOption(op);
  };

  const getDateAmPm = (
    op: string = option,
    hours: any = lHours,
    minutes: any = lMinutes
  ) => {
    const luxonStart = DateTime.fromJSDate(startDate ?? new Date()).setZone(
      timezone
    );

    const newHour = DateTime.fromFormat(`${hours}:${minutes} ${op}`, 'h:mm a');

    const newDate = newHour
      .set({
        year: luxonStart.year,
        month: luxonStart.month,
        day: luxonStart.day,
      })
      .setZone(timezone, { keepLocalTime: true })
      .toJSDate();

    return newDate;
  };

  const handle24Hour = (v: any, value: any) => {
    if (v === '-1' || value > 23) {
      value = 23;
    }

    setLHours(value.toString());

    const luxonStart = DateTime.fromJSDate(startDate).setZone(timezone);
    const newHour = DateTime.fromFormat(`${value}:${lMinutes}`, 't').setZone(
      timezone
    );
    const newDate = newHour
      .set({
        year: luxonStart.year,
        month: luxonStart.month,
        day: luxonStart.day,
      })
      .setZone(timezone, { keepLocalTime: true })
      .toJSDate();

    handleDateChange(newDate);
  };

  const handleAMPMHour = (v: any, value: any) => {
    if (v === '-1' || value > 12) {
      value = 12;
    }
    const newDate = getDateAmPm(option, value);
    handleDateChange(newDate);
    setLHours(value.toString());
  };

  const handleHourChange = (v: any) => {
    let value: number = parseInt(v);
    value = Number.isNaN(value) ? 0 : value;

    if (format24h === '24') {
      return handle24Hour(v, value);
    }
    return handleAMPMHour(v, value);
  };

  const handleMinuteChange = (v: any) => {
    let value: number = parseInt(v);
    //avoiding type error for luxon
    value = Number.isNaN(value) ? 0 : value;
    const luxonStart = DateTime.fromJSDate(startDate).setZone(timezone);

    if (value <= -1 || value >= 60) {
      value = 59;
    }

    if (value >= 0 && value <= 9) {
      setLMinutes('0' + value);
    } else {
      setLMinutes(value.toString());
    }

    const newMinute = DateTime.fromFormat(value.toString(), 'm').setZone(
      timezone
    );

    const hour =
      format24h === 'AMPM' ? getDateAmPm().getHours() : parseInt(lHours);

    const newDate = newMinute
      .set({
        day: luxonStart.day,
        month: luxonStart.month,
        year: luxonStart.year,
        hour: hour,
      })
      .toJSDate();

    handleDateChange(newDate);
  };

  const convertSeletedStartDate = () => {
    const luxonStart = DateTime.fromJSDate(startDate).setZone(timezone);
    const newHour = DateTime.fromFormat('11:00 pm', 'h:mm a');
    const convertedStartDate = newHour
      .set({
        year: luxonStart.year,
        month: luxonStart.month,
        day: luxonStart.day,
      })
      .setZone(timezone, { keepLocalTime: true })
      .toJSDate();
    return convertedStartDate;
  };

  useEffect(() => {
    if (typeof currentView !== 'undefined') {
      setCurrentView(handleViewChangeBasedOnCurrentView(currentView));
    }
  }, [startDate]);

  return hourOnly ? (
    <TkHourPickerWrapper>
      <DatePicker
        {...props}
        selected={convertSeletedStartDate()}
        timeFormat="HH:mm"
        customInput={
          props.activator && (
            <ComponentInput
              setCurrentView={(value: string) => setCurrentView(value)}
              activator={activator}
            />
          )
        }
        renderCustomHeader={(props: any) => {
          if (currentView !== 'year') {
            const title = DateTime.fromJSDate(props.date)
              .setZone(timezone)
              .toFormat('LLLL y');

            return (
              <CustomHeader
                title={title}
                increaseMonth={props.increaseMonth}
                decreaseMonth={props.decreaseMonth}
              />
            );
          }
          return '';
        }}
        showPopperArrow={false}
        showMonthYearPicker={false}
        showYearPicker={false}
        adjustDateOnChange={false}
        shouldCloseOnSelect={currentView === 'false'}
        yearItemNumber={15}
        showTimeInput
        customTimeInput={
          <Grid container justify="center" alignItems="center">
            <TimeOnlyHeader container>
              <TkTypography
                fontFamily="Lato"
                fontSize={16}
                fontWeight="bold"
                color="default"
              >
                {DateTime.fromJSDate(startDate)
                  .setZone(timezone)
                  .toFormat('LLL dd (ccc) y')}
                <TkTooltip
                  title={
                    isRepeat
                      ? t(
                          'event.errors.repeating_task_edit_dates_error'
                        ).toString()
                      : t(
                          'event.errors.imported_task_edit_dates_error'
                        ).toString()
                  }
                >
                  <TkIcon
                    className="fa fa-info-circle"
                    iconSize={14}
                    color="#1565C0"
                    verticalAlign="middle"
                    marginLeft={5}
                  />
                </TkTooltip>
              </TkTypography>
            </TimeOnlyHeader>
            <TimeWrapper container>
              <TimeItem
                type="number"
                value={lHours}
                disableUnderline
                onChange={(e) => handleHourChange(e.target.value)}
              />
              <Divider>:</Divider>
              <TimeItem
                type="number"
                value={lMinutes}
                disableUnderline
                onChange={(e) => handleMinuteChange(e.target.value)}
              />
              {format24h === '24' ? (
                ''
              ) : (
                <TimeSelector style={{ marginLeft: '20px' }}>
                  {option === 'AM' ? (
                    <TimeSelectorItemSelected id="top-item">
                      AM
                    </TimeSelectorItemSelected>
                  ) : (
                    <TimeSelectorItem
                      id="top-item"
                      onClick={() => handleOption('AM')}
                    >
                      AM
                    </TimeSelectorItem>
                  )}
                  {option === 'PM' ? (
                    <TimeSelectorItemSelected id="bottom-item">
                      PM
                    </TimeSelectorItemSelected>
                  ) : (
                    <TimeSelectorItem
                      id="bottom-item"
                      onClick={() => handleOption('PM')}
                    >
                      PM
                    </TimeSelectorItem>
                  )}
                </TimeSelector>
              )}
            </TimeWrapper>
          </Grid>
        }
      />
    </TkHourPickerWrapper>
  ) : (
    <TkDatePickerWrapper>
      <DatePicker
        {...props}
        selected={convertSeletedStartDate()}
        timeFormat="HH:mm"
        onChange={async (date: any) => {
          handleDateChange(date);
        }}
        customInput={
          props.activator && (
            <ComponentInput
              setCurrentView={(value: string) => setCurrentView(value)}
              activator={activator}
            />
          )
        }
        renderCustomHeader={(props: any) => {
          if (currentView !== 'year') {
            const title = DateTime.fromJSDate(props.date)
              .setZone(timezone)
              .toFormat('LLLL y');

            return (
              <CustomHeader
                title={title}
                increaseMonth={props.increaseMonth}
                decreaseMonth={props.decreaseMonth}
              />
            );
          }

          return '';
        }}
        highlightDates={[
          {
            'react-datepicker__day--highlighted_week': memoizedHighlightDates,
          },
        ]}
        showPopperArrow={false}
        showMonthYearPicker={currentView === 'month'}
        showYearPicker={currentView === 'year'}
        adjustDateOnChange={false}
        shouldCloseOnSelect={currentView === 'false'}
        yearItemNumber={15}
        showTimeInput={currentView === 'false' || currentView === undefined}
        customTimeInput={
          <Grid container justify="center" alignItems="center">
            <HorizontalSlider />
            <TimeWrapper container>
              <TimeItem
                type="number"
                value={lHours}
                disableUnderline
                onChange={(e) => handleHourChange(e.target.value)}
              />
              <Divider>:</Divider>
              <TimeItem
                type="number"
                value={lMinutes}
                disableUnderline
                onChange={(e) => handleMinuteChange(e.target.value)}
              />
              {format24h === '24' ? (
                ''
              ) : (
                <TimeSelector style={{ marginLeft: '20px' }}>
                  {option === 'AM' ? (
                    <TimeSelectorItemSelected id="top-item">
                      AM
                    </TimeSelectorItemSelected>
                  ) : (
                    <TimeSelectorItem
                      id="top-item"
                      onClick={() => handleOption('AM')}
                    >
                      AM
                    </TimeSelectorItem>
                  )}
                  {option === 'PM' ? (
                    <TimeSelectorItemSelected id="bottom-item">
                      PM
                    </TimeSelectorItemSelected>
                  ) : (
                    <TimeSelectorItem
                      id="bottom-item"
                      onClick={() => handleOption('PM')}
                    >
                      PM
                    </TimeSelectorItem>
                  )}
                </TimeSelector>
              )}
            </TimeWrapper>
          </Grid>
        }
      />
    </TkDatePickerWrapper>
  );
};

export default TkFullDatePicker;
