import styled from 'styled-components';

import { Box, Input } from '@mui/material';

export const TkTaskWrapperItem = styled(Box)`
  display: flex;
  flex-direction: column;
  user-select: none;
  ${(props) =>
    props.bgcolor === 'selected'
      ? `background-color: #011F41; color: #ffffff;`
      : ''}
`;

export const HorizontalDivider = styled.div`
  background-color: #c8c7cc;
  height: 1px;
  width: 100%;
`;

export const TkTimeReportInfoParagraph = styled.div`
  width: 100%;
  background: #fff 0% 0% no-repeat padding-box;
  padding: 8px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TkTimeReportWarningBanner = styled.div`
  width: 100%;
  background: #011f41 0% 0% no-repeat padding-box;
  padding: 8px;
  display: flex;
  border-radius: 4px;
  opacity: 1;
`;

export const TkTimeReportTextArea = styled(Input)`
  width: 100%;
  letter-spacing: 0px;
  color: #999999;
  display: flex;
  border-radius: 4px;
  background-color: #f5f6fb;
  border: 1px solid #4952a966;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 16px/22px Lato;

  .MuiInput-input {
    padding: 8px;
  }

  &:hover {
    border: 1px solid #4952a966;
  }

  &:focus {
    border: 1px solid #4952a966;
  }
`;
