import React from 'react';

import { Grid } from '@mui/material';
import { TkTaskWrapperItem } from './styles';
import { TkTypography } from '@components/index';
import { TkTimeReportTotalTimeSpentLineProps } from '@components/Drawers/TkTimeReportDrawer/interfaces/timeReport';

const TkTimeReportTotalTimeSpentLine = ({
  label,
  rate,
}: TkTimeReportTotalTimeSpentLineProps): JSX.Element => {
  return (
    <TkTaskWrapperItem
      padding="1rem 0"
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
    >
      <Grid width={'50%'}>
        <TkTypography
          fontSize={'16px'}
          fontFamily="Lato"
          textAlign="left"
          textTransform={'capitalize'}
          color="#011F41"
          letterSpacing={'0px'}
        >
          {label}
        </TkTypography>
      </Grid>
      <Grid width={'50%'} display={'flex'} justifyContent={'end'}>
        <TkTypography
          fontSize={'16px'}
          fontFamily="Lato"
          textAlign="left"
          fontWeight={'bold'}
          color="#011F41"
          letterSpacing={'0px'}
        >
          {rate}
        </TkTypography>
      </Grid>
    </TkTaskWrapperItem>
  );
};

export default TkTimeReportTotalTimeSpentLine;
