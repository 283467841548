import React from 'react';

import { Grid } from '@mui/material';
import { TkTaskWrapperItem, TkTimeReportWarningBanner } from './styles';
import { TkIcon, TkTypography } from '@components/index';
import { useTranslation } from 'react-i18next';

const TkTimeReportWarning = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TkTaskWrapperItem padding="0 1rem">
      <TkTimeReportWarningBanner>
        <Grid width={'7%'}>
          <TkIcon
            className="fa fa-exclamation-circle"
            color="#FFF"
            iconSize={14}
            marginTop={5}
          />
        </Grid>
        <Grid width={'93%'}>
          <TkTypography
            fontSize={'16px'}
            fontFamily="Lato"
            textAlign="left"
            color="#FFF"
            letterSpacing={'0px'}
          >
            {t('timeReportWarning', { ns: 'events' })}
          </TkTypography>
        </Grid>
      </TkTimeReportWarningBanner>
    </TkTaskWrapperItem>
  );
};

export default TkTimeReportWarning;
