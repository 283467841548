import React, { useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import {
  TkTimeReportInfo,
  TkTimeReportLabel,
  TkTimeReportTotalTimeSpent,
  TkTimeReportWarning,
} from './components/index';
import { TkTaskWrapper, TkTaskActions } from './styles';

import {
  TkTypography,
  DynamicButton,
  TkDatePicker,
  TkHourPicker,
} from '@components/index';
import { useDebouncedCallback, useUserSettings } from '@hooks/index';
import { DateGroup } from '@components/Drawers/TkNewTaskDrawer/styles';
import TkDatePickerActivator from '@components/Drawers/TkNewTaskDrawer/components/TkDatePickerActivator';
import { useSetRecoilState } from 'recoil';
import { generateFinalEventDate } from '@helpers/event';
import { eventEnd, eventStart } from '@recoilData/selectors';

import {
  ExpandMore as ExpandMoreIcon,
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
} from '@mui/icons-material';
import {
  TkTaskWrapperItem,
  TkTimeReportTextArea,
} from '@components/Drawers/TkTimeReportDrawer/components/styles';
import { useEditTaskDrawer } from '@components/Drawers/TkEditTaskDrawer/hooks/useEditTaskDrawer';
import { TkTimeReportProps } from '@components/Drawers/TkTimeReportDrawer/interfaces/timeReport';

const TkTimeReportDrawer = (): JSX.Element => {
  const { t } = useTranslation();

  const { event, updateTimeReportRequest, handleDismiss } = useEditTaskDrawer();
  const { userSettings } = useUserSettings();

  const setEventStart = useSetRecoilState(eventStart);
  const setEventEnd = useSetRecoilState(eventEnd);
  const [edited, setEdited] = useState(false);
  const [explanationNote, setExplanationNote] = useState('');

  const emptyError = {
    taskName: { error: false, message: '' },
    startDate: { error: false, message: '' },
    startTime: { error: false, message: '' },
    endDate: { error: false, message: '' },
    endTime: { error: false, message: '' },
    monthRepeatTime: { error: false, message: '' },
  };

  const [eventError, setEventError] = useState(emptyError);

  const checkIfDateIsTheSameDay = (firstDate, secondDate) =>
    firstDate !== null && secondDate !== null
      ? DateTime.fromJSDate(firstDate).hasSame(
          DateTime.fromJSDate(secondDate),
          'day'
        )
      : false;

  const isDateTheSame = checkIfDateIsTheSameDay(event.start, event.end);

  const setStartTime2HoursBeforeTime = (value) =>
    noDebounceSetStartTime(
      DateTime.fromJSDate(value ?? new Date())
        .minus({ hours: 2 })
        .toJSDate()
    );

  const noDebounceSetEndTime = (value, fireSideEffect = true) => {
    setEventEnd(generateFinalEventDate(event.end, value));
    setEdited(true);
    if (value === null) return;

    const newEndTimeBeforeStartTime = value < event.start;
    if (newEndTimeBeforeStartTime && isDateTheSame && fireSideEffect)
      setStartTime2HoursBeforeTime(value);
  };
  const setEndTime = useDebouncedCallback(noDebounceSetEndTime, 500);

  const setEndTime2HoursAfterTime = (value) =>
    noDebounceSetEndTime(
      DateTime.fromJSDate(value ?? new Date())
        .plus({ hours: 2 })
        .toJSDate(),
      false
    );

  const noDebounceSetStartTime = (value) => {
    setEventStart(generateFinalEventDate(event.start, value));
    setEdited(true);
    if (value === null) return;

    const newStartTimeAfterLastStartTime = value > event.end;
    if (newStartTimeAfterLastStartTime) setEndTime2HoursAfterTime(value);
  };

  const setEndDate = (value) => {
    const newEndDate = generateFinalEventDate(value, event.end);
    setEventEnd(newEndDate);
    setEdited(true);
    if (value === null) return;

    const newEndDateBeforeStartDate = newEndDate < event.start;
    const isSameDay = checkIfDateIsTheSameDay(newEndDate, event.start);
    if (newEndDateBeforeStartDate || isSameDay) {
      const newEndTimeBeforeStartTime = event.end < event.start;
      if (newEndTimeBeforeStartTime) {
        setEventStart(newEndDate);
      }
      if (!isSameDay) {
        setStartDate(value);
      }
    }
  };

  const setStartTime = useDebouncedCallback(noDebounceSetStartTime, 500);

  const setStartDate = (value) => {
    const newStartDate = generateFinalEventDate(value, event.start);
    setEventStart(newStartDate);
    setEdited(true);
    if (value === null) return;

    const newStartDateAfterEndDate = newStartDate > event.end;
    if (newStartDateAfterEndDate) {
      setEndDate(value);
    }
  };

  function calculateDiffTime(startTime, endTime) {
    if (!startTime || !endTime) return;
    if (startTime > endTime) return;
    return (endTime.getTime() - startTime.getTime()) / (1000 * 60);
  }

  function convertMinutesToHours(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }

  function showSpentTime(startTime, endTime) {
    if (!startTime || !endTime) return '';
    const calculetedTime = calculateDiffTime(startTime, endTime);
    const convertedTime = convertMinutesToHours(calculetedTime);

    if (convertedTime.minutes > 0)
      return `${convertedTime.hours} ${t('totalHours', { ns: 'events' })} ${convertedTime.minutes} ${t('totalMin', { ns: 'events' })}`;
    else return `${convertedTime.hours} ${t('totalHours', { ns: 'events' })} `;
  }

  function calculateAmout(value) {
    if (!value) return '';
    const calculatedTime = calculateDiffTime(event.start, event.end);
    const valueMinute = value / 60;
    const result = valueMinute * calculatedTime;

    return `$${result.toFixed(2)}`;
  }

  const handleExplanationNote = (value) => {
    setExplanationNote(value);
  };

  const verifyFutureHour = () => {
    const timestampNow = new Date().getTime();
    return event.end.getTime() > timestampNow
  }

  const saveTimeReport = () => {
    let value: TkTimeReportProps = {
      started_at: event.start.getTime(),
      finished_at: event.end.getTime(),
      number_of_people: event.assignedUsers.length,
      complete_for_everyone: true,
    };

    if (event.assignmentId)
     value = {...value, assignment_id: event.assignmentId}

    if (explanationNote)
      value = {...value, edit_reason: explanationNote}

    if (verifyFutureHour()) {

      const hourError = {
        taskName: { error: false, message: '' },
        startDate: { error: false, message: '' },
        startTime: { error: false, message: '' },
        endDate: { error: true, message: t('errorHours', { ns: 'events' }) },
        endTime: { error: true, message: t('errorHours', { ns: 'events' }) },
        monthRepeatTime: { error: false, message: '' },
      };

      setEventError(hourError)
    }
    else updateTimeReportRequest(value);

  };

  return (
    <>
      <TkTaskWrapper>
        <TkTimeReportLabel />
        <TkTimeReportWarning />
        <TkTimeReportInfo />

        <TkTaskWrapperItem padding="0 1rem">
          <DateGroup>
            <Box
              marginRight="10px"
              marginTop="15px"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <TkDatePicker
                  startDate={event.start}
                  handleDateChange={setStartDate}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('startDate', { ns: 'events' })}
                      StartDate={event.start}
                      ErrorForm={eventError.startDate.error}
                      ErrorFormMessage={eventError.startDate.message}
                      Format="LLL dd, y"
                      StartAdornment={CalendarTodayIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
              <Box marginTop="25px" width="100%">
                <TkDatePicker
                  startDate={event.end}
                  handleDateChange={setEndDate}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('endDate', { ns: 'events' })}
                      StartDate={event.end}
                      ErrorForm={eventError.endDate.error}
                      ErrorFormMessage={eventError.endDate.message}
                      Format="LLL dd, y"
                      StartAdornment={CalendarTodayIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
            </Box>
            <Box marginLeft="10px" width="100%">
              <Box marginTop="15px" width="100%">
                <TkHourPicker
                  startDate={event.start}
                  handleDateChange={setStartTime}
                  format24h={userSettings.time_format}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('startTime', { ns: 'events' })}
                      ErrorFormMessage={eventError.startTime.message}
                      StartDate={event.start}
                      ErrorForm={eventError.startTime.error}
                      Format="t"
                      StartAdornment={AccessTimeIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
              <Box marginTop="25px" width="100%">
                <TkHourPicker
                  startDate={event.end}
                  handleDateChange={setEndTime}
                  format24h={userSettings.time_format}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('endTime', { ns: 'events' })}
                      StartDate={event.end}
                      ErrorFormMessage={eventError.endTime.message}
                      ErrorForm={eventError.endTime.error}
                      Format="t"
                      StartAdornment={AccessTimeIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
            </Box>
          </DateGroup>
        </TkTaskWrapperItem>

        <TkTimeReportTotalTimeSpent
          rate={`$${event.rate} ${t('timeReportTextAreaLabel', { ns: 'events' })}`}
          totalEarned={calculateAmout(event.rate)}
          totalTimeSpent={showSpentTime(event.start, event.end)}
        />

        {edited && (
          <>
            <TkTaskWrapperItem padding="1rem">
              <TkTypography
                fontSize={'16px'}
                fontFamily="Lato"
                textAlign="left"
                color="#011F41"
                letterSpacing={'0px'}
              >
                {t('timeReportTextAreaInfo', { ns: 'events' })}
              </TkTypography>
            </TkTaskWrapperItem>

            <TkTaskWrapperItem padding="0 1rem">
              <TkTimeReportTextArea
                value={explanationNote}
                onChange={(e) => handleExplanationNote(e.target.value)}
                multiline
                rows={10}
                placeholder={t('timeReportTextAreaPlaceholder', { ns: 'events' })}
              />
            </TkTaskWrapperItem>
          </>
        )}
      </TkTaskWrapper>

      <TkTaskWrapperItem padding="1rem 0">
        <TkTaskActions>
          <DynamicButton
            color="primary"
            disableElevation
            onClick={handleDismiss}
          >
            {t('cancel', { ns: 'common' })}
          </DynamicButton>
          <DynamicButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={saveTimeReport}
          >
            {t('save', { ns: 'common' })}
          </DynamicButton>
        </TkTaskActions>
      </TkTaskWrapperItem>
    </>
  );
};

export default TkTimeReportDrawer;
