import React from 'react';

import { Grid } from '@mui/material';
import { TkTaskWrapperItem, TkTimeReportInfoParagraph } from './styles';
import { TkTypography } from '@components/index';
import { useTranslation } from 'react-i18next';

const TkTimeReportInfo = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TkTaskWrapperItem padding="1rem 0.5rem">
      <TkTimeReportInfoParagraph>
        <Grid width={'100%'}>
          <TkTypography
            fontSize={'16px'}
            fontFamily="Lato"
            fontWeight={'bold'}
            textAlign="left"
            color="#011F41"
            letterSpacing={'0px'}
          >
            {t('timeReportParagraphTitle', { ns: 'events' })}
          </TkTypography>
        </Grid>
        <Grid width={'100%'} marginTop={'0.5em'}>
          <TkTypography
            fontSize={'16px'}
            fontFamily="Lato"
            textAlign="left"
            color="#999999"
            letterSpacing={'0px'}
          >
            {t('timeReportParagraphInfo', { ns: 'events' })}
          </TkTypography>
        </Grid>
      </TkTimeReportInfoParagraph>
    </TkTaskWrapperItem>
  );
};

export default TkTimeReportInfo;
